import Vue from 'vue'
import Vuex from 'vuex'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        questionnaire: {},
        orderQuestion: [],
        questionLogic: {},
        answers: {},
        hash: null,
        events: [],
        currentEvent: {},
        user: {phone: null}
    },
    getters: {},
    mutations: {
        setAnswer(state, val) {
            console.log('Answer', val);
            state.answers[val.question] = val.answer;
            window.axios.post(process.env.VUE_APP_API_URL + '/api/questionnaire/' +state.questionnaire.questionnaireUser+ '/set-answer', {
                hash: state.hash,
                answer: {
                    question: val.question,
                    text: val.answer.message,
                    answer: val.answer.value,
                },
            }).finally(() => {
                let event = new CustomEvent('setAnswerComplete', {
                    detail: {
                        question: val.question
                    }
                });
                window.dispatchEvent(event);
            });
        },
        setQuestionnaire(state, val) {
            if (Object.keys(state.questionnaire).length) return;
            state.questionnaire = val;
            state.orderQuestion = [];
            if (val.questions) {
                val.questions.forEach(elem => {
                    if (val.logics) {
                        if (state.orderQuestion.indexOf(elem.id) === -1) {
                            state.orderQuestion.push(elem.id);
                        }
                        val.logics.forEach(logic => {
                            if (logic.question == elem.id) {
                                if (!state.questionLogic[elem.id]) state.questionLogic[elem.id] = [];
                                state.questionLogic[elem.id].push(logic);
                            }
                        });
                    } else {
                        state.orderQuestion.push(elem.id);
                    }
                });
                console.log(state);
            }
        },
        setHash(state, val) {
            state.hash = val;
        },
        setEvents(state, val) {
            state.events = val;
        },
        activeEvent(state, val) {
            state.currentEvent = val;
        },
        setUser(state, val) {
            state.user = val;
        }
    },
    actions: {
        getQuestionnaires({commit}) {
            const fpPromise = FingerprintJS.load();
            let result = {};
            return (async () => {
                // Get the visitor identifier when you need it.
                const fp = await fpPromise
                result = await fp.get()
                commit('setHash', result.visitorId);
                return window.axios.get(process.env.VUE_APP_API_URL + '/api/questionnaire/questions', {
                    params: {
                        host: location.host,
                        path: location.pathname,
                        params: location.search.replace('?','').split('&'),
                        hash: result.visitorId,
                    }
                }).then(res => {
                    //commit('setQuestionnaire', res.data);
                    return res.data;
                });
            })();
        },
        getQuestionnaire({commit, state}, {id, eventId}) {
            const fpPromise = FingerprintJS.load();
            let result = {};
            return (async () => {
                // Get the visitor identifier when you need it.
                const fp = await fpPromise
                result = await fp.get()
                commit('setHash', result.visitorId);
                return window.axios.get(process.env.VUE_APP_API_URL + `/api/questionnaire/${id}/current`, {
                    params: {
                        host: location.host,
                        path: location.pathname,
                        params: location.search.replace('?','').split('&'),
                        hash: result.visitorId,
                        event: eventId
                    }
                }).then(res => {
                    commit('setQuestionnaire', res.data);
                    window.axios.post(process.env.VUE_APP_API_URL + '/api/questionnaire/' +state.questionnaire.questionnaireUser+ '/update', {
                        phone: state.user.phone
                    });
                    return res.data;
                });
            })();
        },
        closeQuestionnaire({state}) {
            if (state.orderQuestion.length !== Object.keys(state.answers).length) {
                window.axios.post(process.env.VUE_APP_API_URL + '/api/questionnaire/' + state.questionnaire.questionnaireUser + '/push', {
                    e: 'close', hash: state.hash,
                });
            }
        },
        getEvents({commit}) {
            return window.axios.get(process.env.VUE_APP_API_URL + '/api/event/list', {
                params: {
                    host: location.host,
                    path: location.pathname,
                    params: location.search.replace('?','').split('&')
                }
            }).then(res => {
                commit('setEvents', res.data);
                return res.data;
            });
        },
        updateUser({commit, state}) {
            window.addEventListener('userDataLoaded', (event) => {
                console.log(event.detail);
                if (event.detail.phoneStatus) {
                    commit('setUser', {
                        phone: event.detail.phone
                    });
                    if (state.questionnaire?.questionnaireUser) {
                        window.axios.post(process.env.VUE_APP_API_URL + '/api/questionnaire/' +state.questionnaire.questionnaireUser+ '/update', {
                            phone: state.user.phone
                        });
                    }
                }
            });
            if (window.userData.phoneStatus && window.userData.phone) {
                commit('setUser', {
                    phone: window.userData.phone
                });
                if (state.questionnaire?.questionnaireUser) {
                    window.axios.post(process.env.VUE_APP_API_URL + '/api/questionnaire/' +state.questionnaire.questionnaireUser+ '/update', {
                        phone: state.user.phone
                    });
                }
            }
        }
    },
    modules: {}
})
