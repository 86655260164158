<template>
    <div>
        <questionnaire :is-static="isStatic"/>
    </div>
</template>

<script>
import Questionnaire from "@/components/QuestionnaireForm";

export default {
    name: 'App',
    props: ['isStatic'],
    components: {
      Questionnaire
    },
    created() {
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
